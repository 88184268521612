import React from "react";
import { Link } from "gatsby";
// import Img from "gatsby-plugin-image";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const Card = ({ article }) => {
  return (
    <Link to={`/article/${article.node.slug}`} className="uk-link-reset">
      <div className="uk-card uk-card-muted">
        <div className="uk-card-media-top">
          {/* <Img
            fixed={article.node.image.localFile.childImageSharp.fixed}
            imgStyle={{ position: "static" }}
          /> */}
          <GatsbyImage image={getImage(article.node.image.localFile)}  
          alt={article.node.image.alternativeText}
          layout="constrained"/>

        </div>
        <div className="uk-card-body">
          <p id="category" className="uk-text-uppercase">
            {article.node.category.name}
          </p>
          <p id="title" className="uk-text-large">
            {article.node.title}
          </p>
          <div>
            <hr className="uk-divider-small" />
            <div className="uk-grid-small uk-flex-left" data-uk-grid="true">
              <div>
                 {article.node.author.picture && (
                   <GatsbyImage image={getImage(article.node.author.picture.localFile)} 
                    imgStyle={{ borderRadius: "50%" }} 
                    alt={article.node.author.name}/>
                 )}
                {/* {article.node.author.picture && (
                  <Img
                    fixed={article.node.author.picture.localFile.childImageSharp.fixed}
                    imgStyle={{ position: "static", borderRadius: "50%" }}
                  />
                )} */}
              </div>
              <div className="uk-width-expand">
                <p className="uk-margin-remove-bottom">
                  {article.node.author.name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;